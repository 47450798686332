import {create} from 'zustand'

const useStore = create((set) => ({
    bears: 0,
    posts: [
        {
            nickname: 'Person NickName',
            date: '01.01.2021',
            image: 'https://picsum.photos/2000',
            text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. ,  provident laborios am ab, ex blanditiis earum. Itaque, et!',
            likes: [
                {
                    nickname: 'John Doe',
                },
                {
                    nickname: 'Johane Diez',
                }
            ],
            comments: [
                {
                    nickname: 'PerNickName John Doe Yevlampievich',
                    comment: 'quisquam autem optio nemo provident laboriosam ab, ex blanditiis earum. Itaque , et!',
                    date: '01.01.2021'
                }, {
                    nickname: 'PerNickName John Doe Yevlampievich',
                    comment: 'quisquam autem optio  ab, ex blanditiis earum. Itaque , et!',
                    date: '01.01.2021'
                }
            ]
        }, 
        {
            nickname: 'Person NickName',
            date: '01.01.2021',
            image: 'https://img.freepik.com/free-vector/sport-text-banner-poster-design_1308-132612.jpg?size=626&ext=jpg&ga=GA1.1.553209589.1714694400&semt=ais',
            text: ' adipisicing elit. Dolorem magnam corporis sunt   dolores aliquid sunt  Itaque, et!',
            likes: [],

            comments: [
                {
                    nickname: 'PerNickName John Doe Yevlampievich',
                    comment: 'quisquam autem optio nemo provident laboriosam ab, ex blanditiis earum',
                    date: '01.01.2021'
                }, {
                    nickname: 'PerNickName John Doe Yevlampievich',
                    comment: 'quisquam autem optio nemo provident laboriosam ab, ex blanditiis earum. Itaque, et!',
                    date: '01.01.2021'
                }, {
                    nickname: 'PerNickName John Doe Yevlampievich',
                    comment: 'quisquam autem optio nemo provident, et!',
                    date: '01.01.2021'
                }
            ]
        }, 
        {
            nickname: 'Person NickName',
            date: '01.01.2021',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQArLbFSKRlBLVm_tJ5jfkMP9' +
                    'iblyzl_t258EeUUQS7u4Ivh9bjdZwuLLjlLhGTWY6Z6Zg&usqp=CAU',
            text: 'Lorem Itaque, et!',
            likes: [],

            comments: [
                {
                    nickname: 'PerNickName John Doe Yevlampievich',
                    comment: 'quisquam autem optio nemo provident laboriosam ab, ex blanditiis earum',
                    date: '01.01.2021'
                }, {
                    nickname: 'PerNickName John Doe Yevlampievich',
                    comment: 'quisquam autem optio nemo provident laboriosam ab, ex blanditiis earum. Itaque, et!',
                    date: '01.01.2021'
                }, {
                    nickname: 'PerNickName John Doe Yevlampievich',
                    comment: 'quisquam autem optio nemo provident, et!',
                    date: '01.01.2021'
                }
            ]
        }
    ],
    addLikes: (id, like) => set((state) => ({
        posts: state.posts.map((post, index) => {

            if(index === id) {

                console.log( post )

                return post.likes.filter((likePerson, index) => likePerson.nickname === like.nickname).length === 0
                    ? {...post, likes: [...post.likes, like]} 
                    : {...post, likes: post.likes.filter((likePerson, index) => likePerson.nickname !== like.nickname)} 

            }
             
            return post;
        })
    })),
    
    /* removeLikes: (id, likeId) => set((state) => ({
        posts: state.posts.map((post, index) => index === id ? {...post, likes: post.likes.filter((like, index) => index !== likeId)} : post)
    })), */
    
    addComment: (id, comment) => set((state) => ({
        posts: state.posts.map((post, index) => index === id ? {...post, comments: [...post.comments, comment]} : post)
    })),
    removeComment: (id, commentId) => set((state) => ({
        posts: state.posts.map((post, index) => index === id ? {...post, comments: post.comments.filter((comment, index) => index !== commentId)} : post)
    })),
    
    /* 
        & ********************************************************************************************************
    */
    increasePopulation: () => set((state) => ({
        bears: state.bears + 1
    })),
    removeAllBears: () => set({bears: 0}),
    updateBears: (newBears) => set({bears: newBears})
}));

export default useStore;