// 
import sprite from '../sprites/solid.svg';
import './header.css';

import { Link } from "react-router-dom";

const Header = () => {

    const handleClickLink = () => {
        document.querySelector('.burger__icon-wrapper').classList.remove('active');
        document.querySelector('nav').classList.remove('active');
    }
    const handleClick = (e) => {
        //console.log(e.target);console.dir(e);console.log('clicked');console.log(e.currentTarget);
        e.currentTarget.classList.toggle('active');
        document.querySelector('nav').classList.toggle('active');
    }

    return (
        <header className="App-header">
            <div className="container container__header">
                <div className="app__logo ">
                    <svg className='logo_icon'> <use href= {sprite + '#person-walking'} /> </svg>
                </div>

                <nav>
                    <ul>
                        <li onClick={handleClickLink}>
                            <Link to="/">Home</Link>
                        </li>
                        <li onClick={handleClickLink}>
                            <Link to="/blogs">Blogs</Link>
                        </li>
                        <li onClick={handleClickLink}>
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li onClick={handleClickLink}>
                            <Link to="/signin">Signin</Link>
                        </li>
                    </ul>
                </nav>

                <div className="burger__icon-wrapper"
                    onClick={(e) => handleClick(e)} >

                    <svg className="burger__icon"> <use href= {sprite + '#bars'} /> </svg>
                    <svg className="burger__icon close"> <use href= {sprite + '#xmark'} /> </svg>
                </div>
            </div>
        </header>
    );
};

export default Header;