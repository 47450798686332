/* */
import useStore from '../store/myStore.js';

import spriteSolid from '../components/sprites/solid.svg';
import spriteRegular from '../components/sprites/regular.svg';

import { useRef, useState, useEffect } from 'react';

import '../css/blogs.css';

const Blogs = () => {


    const postsState = useStore((state) => state.posts)
    const handleClickLink = () => {
      
    }
    function Comments(props) {
        const [like, setLike] = useState(false);
        const likeRef = useRef();
    
        const [comment, setcomment] = useState(false);

        const addLikes = useStore((state) => state.addLikes);

        const handleClickActionLike = (e) => {
            // /* */ // e.stopPropagation(); e.nativeEvent.stopImmediatePropagation();
            setLike(!like);
            e.currentTarget.classList.toggle('active');

            
            likeRef.current = !likeRef.current;

            /* console.log( `e.currentTarget: ` );
            console.log( e.currentTarget );
            console.log( `likeRef: ` );
            console.log( likeRef );
            console.log( `like: ` );
            console.log( like ); */

            /*console.log(likeRef.current); */
            const post = e.currentTarget.closest('.post');
            let postId = parseInt(post.dataset.id);
            addLikes(postId, {nickname: 'Name Surename'});

        }
        const handleClickActionComment = (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
        
            setcomment(!comment);
        }
    
        return (

            <div className="post__footer post-container">
                <div className="post__footer-info">
                    <div className="post__footer-actions">
                        <div className="footer__action action-like"
                            onClick={(e) => handleClickActionLike(e)}>
                                
                            {!likeRef.current 
                                ? <svg className='action__icon icon-like icon-regular'> <use href= { spriteRegular + '#heart' } /> </svg>
                                : <svg className='action__icon icon-like icon-solid'> <use href= { spriteSolid + '#heart' } /> </svg>}
                            <span className="count__likes" > {props.post.likes.length} </span>
                        </div>
                        <div className="footer__action action-comment"
                            onClick={(e) =>  handleClickActionComment(e)}>

                            <svg className='action__icon icon-comment'> <use href= {spriteRegular + '#comment'} /> </svg>
                            <span className="count__comments" > {props.post.comments.length} </span>
                        </div>
                    </div>
                </div>

                
                {comment && <div className="post__footer-comments">
                    <div className="send__comment">
                        <textarea name="send-comment" type="text" placeholder='Write your comment' />
                        <svg className='action__icon icon-like icon-solid'> <use href= { spriteRegular + '#paper-plane' } /> </svg>
                    </div>

                    {props.post.comments.map((comment, index) => {
                        return (
                            <div className="comment" key={index}>
                                <div className="comment__title">
                                    <div className="comment__username"> {comment.nickname} </div>
                                    <div className="comment__date"> {comment.date} </div>
                                </div>
                                <div className="comment__body">
                                    <p>
                                        {comment.comment}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>}
            </div>

        )
    }
    return (
        <article className='App-blogs'>
            <div className="container">
                <h1>Blog Articles</h1>

                <div className="posts"> 
                    {postsState.map((post, index) => {
                        return (
                            <div className="post" key={index} data-id={index}>
                                <div className="post__header post-container">
                                    <div className="post__title"> 
                                        <h3>{post.nickname}</h3>  
                                    </div>
                                    <div className="post__link"> 
                                        <div className="post__date"> {post.date} </div>
                                    </div>
                                </div>
                                <div className="post__body">
                                    <div className="post__image">
                                        <img src={post.image} alt="" srcSet="" />
                                    </div>
                                    <div className="post__text post-container">
                                        <p>
                                            {post.text}
                                        </p>
                                    </div>
                                </div>

                                <Comments post = {post} />
                            </div>
                        )})
                    }
                </div>
            </div>
        </article>    
    );
};

export default Blogs;

