import sprite from '../sprites/regular.svg';
import './footer.css';

const Footer = () => {
    return (
        <footer className="App-footer">
            <div className="app__logo"></div>
            <span>React && .Net Studio ltd.inc </span>
            <svg className="burger__icon close"> 
                <use href= {sprite + '#copyright'} /> 
            </svg>
        </footer>
    );
};

export default Footer;