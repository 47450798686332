/*  */
import '../css/home.css';

import { useState, useEffect } from 'react';

const Home = () => {

    /* 
    https://localhost:7216  http://localhost:5156
    
    // const curreWeather = fetch(`https://api.openweathermap.org/data/2.5/weather?q=${data.city}&units=metric&lang=ru&appid=${apiKey}`);
    // const forcast8Days = fetch(`https://api.openweathermap.org/data/2.5/onecall?lat=${data.lat}&lon=${data.lon}&exclude=current,minutely,hourly&units=metric&lang=ru&appid=${apiKey}`);
    */
    
    navigator.geolocation.getCurrentPosition((position) => {
        const coords = { lat: '', lon: '' }
        coords.lat = position.coords.latitude; 
        coords.lon = position.coords.longitude;
    
        console.log('coords ', coords);
        return  coords;
    });

    const apiKey = "ac4d8af28a8c864ae7422cba18ba1e76";

    const data = {
        city: "Kyiv",
        lat: 50.458,
        lon: 30.5303
    }

    let urls = {
        urlWeather: `https://api.openweathermap.org/data/2.5/weather?q=${data.city}&units=metric&lang=ua&appid=${apiKey}`,
        urlforcast: `https://api.openweathermap.org/data/2.5/onecall?lat=${data.lat}&lon=${data.lon}&exclude=current,minutely,hourly&units=metric&lang=ua&appid=${apiKey}`,
    }
    let user = {
        name: 'John',
        surname: 'Smith'
    };
    function optionsPost(user) {
        return {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        }
    }
    useEffect(() => {
        
        fetch('https://ipapi.co/json/')
            .then((res) => res.json())
            .then((json) => { console.log(json); });
        fetch(urls.urlWeather)
            .then((res) => res.json())
            .then((json) => { console.log(json); });
        fetch(urls.urlforcast)            
            .then((res) => res.json())
            .then((json) => { console.log(json); });
    }, [])
    useEffect(() => {
        fetch("http://localhost:5156/WeatherForecast/GetWeatherForecast")
            .then((res) => res.json())
            .then((json) => { 
                console.log(json);
            });
    }, [])
    useEffect(() => {
        fetch("http://localhost:5156/WeatherForecast/Test")
            .then((res) => res.text())
            .then((json) => { 
                console.log(json);
            });
    }, [])

    

    return (
        <article className='App-home'>
            <h1>Home</h1>
        </article>    
    );
};

export default Home;