/*  */
import '../css/contacts.css';

import useStore from '../store/myStore.js';

const Contact = () => {

    
    function BearCounter() {
        const bears = useStore((state) => state.bears)

        return (
            <h1>{bears} around here...</h1>
        )
    }
      
    function Controls() {
        const increasePopulation = useStore((state) => state.increasePopulation)
        const removeAllBears = useStore((state) => state.removeAllBears)

        return (
            <div className="controls-wrapper">
                <button onClick={removeAllBears}>remove all</button>
                <button onClick={increasePopulation}>one up</button>
            </div>
        )
    }

    return (
    
        <section>
            <h1>Contact Me</h1>
        
            <div className="container-test">
                <BearCounter />
                <Controls />
            </div>
        
        </section>
    
    );





};
  
export default Contact;