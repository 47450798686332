import { Outlet } from "react-router-dom";

// import '../App.css';
import Header from "../components/partials/Header";
import Footer from "../components/partials/Footer";

const Layout = () => {
  return (
    <section className="content">
        <Header />
        <main className="main main__content">
            <Outlet />
        </main>
        <Footer />
    </section>
  )
};
export default Layout;